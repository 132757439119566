<template>
  <div class="questionDetails">
    <div class="navBar">
      <div @click="$router.go(-1)">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">问题</div>
      <div class="rightBtn"></div>
    </div>

    <div class="questionCard">
      <div class="questionTitle">{{ questionInfo.question }}</div>
      <div class="imgList">
        <div class="imgItem" v-for="(item, index) in questionInfo.images" :key="index" @click="imagePreview(index)">
          <ImgDecypt :src="item" />
        </div>
      </div>
      <!-- <div class="questionContent">***************</div> -->
      <div class="questionData">
        <svg-icon class="questionIcon" iconClass="question_time"></svg-icon>
        <div class="dataText">{{ questionInfo.createdAt | dateago }}</div>
        <svg-icon class="questionIcon" iconClass="question_eye"></svg-icon>
        <div class="dataText">{{ questionInfo.lookCount | watchCount }}</div>
      </div>
    </div>

    <!-- 评论列表 -->
    <div class="commentList">
      <PullRefresh :loading="loading" :refreshing="refreshing" :finished="finished" @onLoad="onLoad"
        @onRefresh="onRefresh" :error="error" disabled>
        <div class="comment_item" v-for="item in comList" :key="item.id">
          <div class="left-avatar" :class="{isCertifica: item.merchantUser == 1}">
            <router-link tag="div" :to="`/userHomePage?uid=${item.userID}`" class="link_img_box">
              <ImgDecypt :src="item.userPortrait" round />
            </router-link>
            <div class="certification" v-if="item.superUser">
              <Vlogo :vid="item.id" />
            </div>
            <svg-icon class="certificaBorder" iconClass="certificaBorder" v-if="item.merchantUser == 1"/>
            <!-- <svg-icon class="certification" iconClass="certification"
              v-if="item.superUser"></svg-icon> -->
          </div>
          <div class="right-content">
            <div class="username">
              <div class="userBox">
                <router-link tag="div" :to="`/userHomePage?uid=${item.userID}`" class="name ellipsis" :class="{vipName: checkVip(item)}">{{ item.userName }}</router-link>
                <svg-icon class="userIcon" iconClass="crown" v-if="checkVip(item)">
                </svg-icon>
                <!-- <div class="userIcon" v-for="icon in userIcons(item)" :key="icon.number">
                    <ImgDecypt :src="icon.imgUrl" />
                </div> -->
              </div>

              <div class="adopted" v-if="item.id == questionInfo.adoptionCmtId">已采纳</div>
            </div>
            <p class="text">{{ item.content }}</p>
            <div class="text_operate">
              <div class="text_operate_left">
                <span class="time">{{ item.createdAt | dateago }}</span>
              </div>
              <div class="text_operate_right" @click.stop="reply(item)">
                <svg-icon icon-class="question_comment"></svg-icon>
                <span>回复</span>
              </div>
              <div class="text_operate_right" @click="adoption(item)"
                v-if="!questionInfo.isAdoption && questionInfo.uid == $store.getters.userInfo.uid">
                <svg-icon icon-class="question_adoption"></svg-icon>
                <span>采纳</span>
              </div>
              <div></div>
              <div class="text_operate_right" @click="like(item)">
                <svg-icon :icon-class="item.isLike ? 'zaned' : 'question_like'"></svg-icon>
                <span>{{ item.likeCount ? (item.likeCount | watchCount) : "点赞" }}</span>
              </div>
            </div>
            <!-- 展开更多-->
            <div class="more" @click="commentClick(item)" v-if="item.Info && item.Info.length>0">
              <span>{{ item.isExpand ? "收起" : "展开" }}{{ item.Info.length }}条回复</span>
              <div></div>
              <svg-icon icon-class="sanjiao" :class="{rotate180:item.isExpand}" />
              <div v-if="!isExpand"></div>
            </div>
            <!-- 二级评论-->
            <div class="comment2" v-if="item.isExpand">
              <div class="flex" v-for="subItem in item.Info" :key="subItem.id">
                <div class="left-avatar" :class="{isCertifica: subItem.merchantUser == 1}">
                  <router-link tag="div" :to="`/userHomePage?uid=${subItem.userID}`" class="link_img_box">
                    <ImgDecypt :src="subItem.userPortrait" round />
                  </router-link>
                  <div class="certification" v-if="subItem.superUser == 1">
                    <Vlogo :vid="subItem.id" />
                  </div>
                  <svg-icon class="certificaBorder" iconClass="certificaBorder" v-if="subItem.merchantUser == 1"/>
                  <!-- <svg-icon class="certification" iconClass="certification"
                    v-if="subItem.superUser == 1">
                  </svg-icon> -->
                </div>
                <div class="right-content">
                  <div class="username">
                    <!-- <div class="name ellipsis">{{subItem.userName}}</div> -->
                    <div class="userBox">
                      <router-link tag="div" :to="`/userHomePage?uid=${subItem.userID}`" class="name ellipsis" :class="{vipName: checkVip(subItem)}">{{ subItem.userName }}</router-link>
                      <svg-icon class="userIcon" iconClass="crown" v-if="checkVip(subItem)"></svg-icon>
                      <!-- <div class="userIcon" v-for="icon in userIcons(subItem)" :key="icon.number">
                          <ImgDecypt :src="icon.imgUrl" />
                      </div> -->
                    </div>

                  </div>
                  <p class="text">{{ subItem.content }}</p>
                  <div class="text_operate">
                    <div class="text_operate_left" @click.stop="reply(item, subItem)">
                      <span class="time">{{ subItem.createdAt | dateago }}</span>
                      <span>回复</span>
                    </div>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </PullRefresh>
    </div>


    <div class="common_input_box defaultInputBox" @click="showCommentInp">
      <div class="sendInout defaultInput">请输入您想说的</div>
      <div class="sendIcon">
        <svg-icon icon-class="plane" />
      </div>
    </div>

    <!-- 输入框 -->
    <van-popup v-if="isPopShow" v-model="isPopShow" position="bottom" class="common_input_box" @click-overlay="close"
      :close-on-click-overlay="true">
      <van-field v-model="commentValue" :border="false" :center="true" :placeholder="placeholderVal"
        autofocus="autofocus" class="sendInout" ref="commentInp" @input="changeInput" />
      <div class="sendIcon" @click.stop="sendMessage">
        <svg-icon icon-class="plane" />
      </div>
    </van-popup>
  </div>
</template>

<script>
  import Vlogo from "@/components/Vlogo"
  import {
    queryQuestCommont,
    commentSend,
    adoption,
    queryQuestInfo,
  } from "@/api/mine";
  import {
    getMedal
  } from "@/utils/getConfig";
  import {
    cancleZan,
    zan
  } from "@/api/user";
  import ImgDecypt from "@/components/ImgDecypt"
  import PullRefresh from "@/components/PullRefresh";
  import {
    Field,
    Popup,
    Toast
  } from "vant";

  export default {
    name: 'QuestionDetails',
    components: {
      Vlogo,
      ImgDecypt,
      PullRefresh,
      [Field.name]: Field,
      [Popup.name]: Popup,
    },
    data() {
      return {
        comList: [],
        isExpand: false, // 展开更多
        isPopShow: false, // 评论输入框
        commentValue: '', // 评论信息
        objID: {}, // id
        pageNumber: 1, // 页码
        pageSize: 10, // 条数
        loading: true, // loading
        refreshing: false, // 刷新状态
        finished: false, // 下拉状态
        error: false, // 是否显示错误信息
        placeholderVal: '请输入您想说的', // 输入框提示
        replyId: '', // 回复ID
        subReplyId: '', // 二级回复ID
        level: 1, // 一级评论: 1   二级评论: 2
        maxlength: 120,
        questionInfo: {}, // 问题信息
      }
    },
    created() {
      this.deCodeParams();
      this.queryQuestInfo();
      this.queryCommont();
    },
    methods: {
      changeInput() {
        if (this.commentValue.length > this.maxlength) {
          this.commentValue = this.commentValue.slice(0, this.maxlength)
        }
        if (this.commentValue.length === this.maxlength) {
          Toast({
            message: "最多只能输入" + this.maxlength + "个字符",
            position: 'bottom',
          })
        }
      },
      userIcons(item) {
        return getMedal(item?.awards || []) || []
      },
      // 解码
      deCodeParams() {
        let data = this.$route.query.data;
        let decodeData = window.atob(data);
        this.objID = JSON.parse(window.decodeURI(decodeData)).id;
      },
      async queryQuestInfo() {
        let req = {
          id: this.objID
        }
        let res = await this.$Api(queryQuestInfo, req);
        if (res.code === 200) {
          this.questionInfo = res.data.info;
        }
      },
      // 查询评论
      async queryCommont() {
        let req = {
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
          objID: this.objID
        }
        let res = await this.$Api(queryQuestCommont, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let arr = res.data.list ? res.data.list : [];
          this.comList = this.comList.concat(arr);
          if (!res.data.hasNext) {
            this.finished = true;
            return;
          }
        } else {
          this.error = true;
        }
      },
      // 上拉加载
      onLoad() {
        this.loading = true;
        this.pageNumber++
        this.queryCommont();
      },
      // 下拉刷新
      onRefresh() {
        this.pageNumber = 1;
        this.comList = [];
        this.refreshing = true;
        this.finished = false;
        this.loading = true;
        this.queryCommont();
      },
      // 展开二级评论
      commentClick(item) {
        this.isExpand = !this.isExpand
        item.isExpand = !item.isExpand;
      },

      // 显示输入框
      showCommentInp() {
        this.isPopShow = true;
        this.$nextTick(() => {
          this.$refs.commentInp.focus()
        })

      },
      // 关闭输入框
      close() {
        // console.log('关闭')
        this.commentValue = "";
        this.replyId = "";
        this.level = 1;
        this.subReplyId = "";
        this.placeholderVal = "请输入您想说的";
      },
      // 校验是否会员、会员时间是否到期
      checkVip(item) {
        let todayTime = new Date().getTime();
        let effectiveTime = new Date(item.vipExpireDate).getTime();
        if (item && item.vipLevel > 0 && todayTime < effectiveTime) {
          return true;
        }
        return false;
      },
      // 回复
      reply(item, subItem) {
        this.level = 2;
        this.replyId = item.id;
        this.subReplyId = subItem?.id;
        this.placeholderVal = '@' + (subItem ? subItem.userName : item.userName);
        this.showCommentInp()
      },
      // 发送评论/回复
      async sendMessage() {
        let req = {
          objID: this.objID,
          cid: this.replyId,
          rid: this.subReplyId,
          content: this.commentValue,
          level: this.level,
          cmtType: "desire",
        }
        // console.log(req);
        // return
        this.$store.commit('app/SET_LOADING', true);
        try {
          let res = await this.$Api(commentSend, req);
          this.$store.commit('app/SET_LOADING', false);
          if (res && res.code == 200) {
            if (this.level == 1) {
              this.comList.unshift(res.data);
            } else {
              this.comList.forEach(i => {
                if (i.id == req.cid) {
                  i.Info = i.Info ? i.Info : [];
                  i.Info.unshift(res.data)
                }
              })
            }
            this.close();
            this.isPopShow = false;
          } else {
            Toast(res.tip || "发送失败，请稍后再试")
          }
        } catch (error) {
          this.$store.commit('app/SET_LOADING', false);
          Toast("操作失败");
        }


      },
      //点赞
      async like(item) {
        let isLike = item.isLike;
        let id = item.id;
        let req = {
          type: "comment",
          objID: id,
        }
        try {
          if (isLike) {
            let res = await this.$Api(cancleZan, req);
            if (res.code === 200) {
              item.isLike = false;
              item.likeCount--;
              Toast("取消点赞")
              return;
            }
            Toast(res.tip || "取消失败");
          } else {
            let res = await this.$Api(zan, req);
            if (res.code === 200) {
              item.isLike = true;
              item.likeCount++;
              Toast("点赞成功")
              return;
            }
            Toast(res.tip || "点赞失败");
          }
        } catch (e) {
          Toast("操作失败");
        }
      },
      // 采纳
      async adoption(item) {
        let req = {
          desireId: this.objID,
          commentId: item.id,
        }
        this.$store.commit('app/SET_LOADING', true);
        try {
          let res = await this.$Api(adoption, req);
          this.$store.commit('app/SET_LOADING', false);
          if (res && res.code == 200) {
            this.questionInfo.adoptionCmtId = item.id;
            this.questionInfo.isAdoption = true;
            this.comList.forEach((i, j) => {
              if (i.id == item.id) {
                this.comList.splice(j, 1);
                this.comList.unshift(i);
              }
            })
            // console.log("12312313")
            Toast(res.tip || "采纳成功");
            return;
          }
          Toast(res.tip || "点赞失败");
        } catch (error) {
          this.$store.commit('app/SET_LOADING', false);
          Toast("操作失败");
        }

      },
      // 图片预览
      imagePreview(index) {
        this.$router.push({
          name: "PicturePreview",
          params: {
            imgs: this.questionInfo.images,
            index: index
          }
        })
      }
    },

  }
</script>

<style lang='scss' scoped>
  .questionDetails {
    height: 100%;
    overflow-y: auto;
    padding: 0 16px;
    -webkit-overflow-scrolling: touch;
    .vipName {
      color: #ff678f !important;
    }
    .navBar {
      box-sizing: border-box;
      height: 46px;
      // margin-bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .leftBtn {
        width: 24px;
        height: 24px;
      }

      .title {
        font-size: 20px;
      }

      .rightBtn {
        width: 24px;
        height: 24px;
      }
    }

    .questionCard {
      // height: 119px;
      padding: 11px 10px;
      box-sizing: border-box;
      border-radius: 4px;
      background: #1e1e1e;

      .questionTitle {
        font-size: 18px;
        // height: 69px;
      }

      .imgList {
        margin: 12px 0 22px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 6px;

        .imgItem {
          // width: 128px;
          height: 128px;
          border-radius: 4px;
          overflow: hidden;
        }
      }

      .questionContent {
        font-size: 20px;
        margin-top: 10px;
      }

      .questionData {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .questionIcon {
          width: 13px;
          height: 13px;
          margin-right: 5px;
        }

        .dataText {
          font-size: 12px;
          margin-right: 40px;
        }
      }
    }

    .commentList {
      // height: calc(100% - 49.5px);
      // height: calc(100% - 214.5px);
      // padding-bottom: 49.5px;
      // background: #FFF;
      // height: 100%;
      margin-bottom: 49.5px;
      box-sizing: border-box;
    }

    .comment_item {

      display: flex;
      padding-top: 20px;

      .left-avatar {
        .link_img_box {
          height: 100%;
        }

        width: 40px;
        height: 40px;
        margin-right: 16px;
        flex-shrink: 0;
        position: relative;

        .certification {
          width: 15.1px;
          height: 15.2px;
          position: absolute;
          right: 0;
          bottom: 0;
          overflow: hidden;
          border-radius: 50%;
          -webkit-backface-visibility: hidden;
          -moz-backface-visibility: hidden;
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
        }
        .certificaBorder {
          position: absolute;
          top: -8.2px;
          width: 100%;
          font-size: 25px;
          z-index: 1;
        }
      }

      .isCertifica {
        border-radius: 50%;
        border: 1.5px solid #d82d0b;
      }


      .right-content {
        width: 100%;
        overflow: hidden;

        .username {
          padding-bottom: 8px;
          display: flex;
          justify-content: space-between;

          // .right-content {
          //   width: 100%;
          //   overflow: hidden;

          //   .username {
          //     padding-bottom: 8px;
          //     display: flex;
          //     justify-content: space-between;

              .userBox {
                display: flex;
                align-items: center;

                .userIcon {
                  width: 16px;
                  height: 16px;
                  margin-right: 10px;

                  /deep/ .van-image {
                    background: #00000000 !important;
                  }
                }
              }

              .vipName {
                color: #ff678f;
              }

              .name {
                // width: 250px;
                font-size: 16px;
                margin-right: 10px;
                //color: #ff7600;
                color: #e7e7e7;
              }


              .adopted {
                width: 58px;
                height: 24px;
                line-height: 24px;
                border-radius: 24px;
                font-size: 14px;
                background: rgb(51, 51, 51);
                text-align: center;
              }
            }


            .text {
              color: #c2c5c7;
              line-height: 22px;
              font-size: 17px;
              margin-right: 30px;
            }

            .text_operate {
              display: flex;
              justify-content: space-between;
              font-size: 12px;
              padding-bottom: 8px;
              margin-top: 8px;

              .text_operate_left {
                flex: 2;
                display: flex;
                color: #7c879f;
                line-height: 20px;

                .time {
                  display: inline-block;
                  padding-right: 15px;
                  font-size: 14px;
                }
              }

              .text_operate_right {
                margin-left: 25px;

                svg {
                  font-size: 16px;
                  padding-right: 5px;
                }
              }
            }

            .more {
              font-size: 12px;
              color: #7c879f;
              display: flex;
              align-items: center;

              >svg {
                font-size: 13px;
              }
            }

            .rotate180 {
              transform: rotate(180deg);
            }
          }

          .userIcon {
            width: 16px;
            height: 16px;
            margin-right: 10px;

            /deep/ .van-image {
              background: #00000000 !important;
            }
          }
        }

        .name {
          // width: 250px;
          font-size: 16px;
          margin-right: 10px;
          //color: #ff7600;
          color: #e7e7e7;
        }


        .adopted {
          width: 58px;
          height: 24px;
          line-height: 24px;
          border-radius: 24px;
          font-size: 14px;
          background: rgb(51, 51, 51);
          text-align: center;
        }
      }


      .text {
        color: #c2c5c7;
        line-height: 22px;
        font-size: 17px;
        word-wrap: break-word;
      }

      .text_operate {
        display: flex;
        justify-content: space-between;
        font-size: 12px;
        padding-bottom: 8px;
        margin-top: 8px;

        .text_operate_left {
          flex: 2;
          display: flex;
          color: #7c879f;
          line-height: 20px;

          .time {
            display: inline-block;
            padding-right: 15px;
            font-size: 14px;
          }
        }

        .text_operate_right {
          margin-left: 25px;

          svg {
            font-size: 16px;
            padding-right: 5px;
          }
        }
      }

      .more {
        font-size: 12px;
        color: #7c879f;
        display: flex;
        align-items: center;

        >svg {
          font-size: 13px;
        }
      }

      .rotate180 {
        transform: rotate(180deg);
      }
    // }

    .comment2 {
      padding-top: 23px;
    }

  // }

  .common_input_box {
    height: 100%;
    height: 49.5px;
    padding: 0 16px;
    box-sizing: border-box;
    background: #1f1f1f;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sendInout {
      width: 362px;
      height: 34px;
      border-radius: 17px;
      background: #2c2c2c;

      /deep/ .van-field__control {
        color: #fff;
        font-size: 16px;
      }

      ::placeholder {
        color: #939bac;
      }

    }

    .sendIcon {
      width: 18px;
      height: 18px;
    }
  }

  .defaultInputBox {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;

    .defaultInput {
      line-height: 34px;
      padding: 0 14px;
      box-sizing: border-box;
      font-size: 16px;
      color: #939bac;
    }
  }
</style>